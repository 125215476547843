



































































































































































































.courseBox {
  display: flex;
  flex-wrap: wrap;
  .form-cell {
    width: 50%;
  }
}
/deep/ .el-switch__label--left.is-active + .el-switch__core{
  background: #ff4949!important;
}
